import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/MainLayout"
import Seo from "../components/WebSeo"
import * as styles from "./404.module.scss"

const NotFoundPage = ({ data }) => {
  const image = getImage(data.file.childImageSharp.gatsbyImageData)
  return (
    <Layout>
      <Seo title="404: Not found" />
      <section className={styles.container}>
        <h1>404: Not Found</h1>
        <div className={styles.image}>
          <GatsbyImage image={image} alt="not found" />
        </div>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </section>
    </Layout>
  )
}
export const query = graphql`
  {
    file(relativePath: { eq: "404.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`

export default NotFoundPage
